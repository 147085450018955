/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-medical-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1m-3 2v.634l.549-.317a.5.5 0 11.5.866L7 7l.549.317a.5.5 0 11-.5.866L6.5 7.866V8.5a.5.5 0 01-1 0v-.634l-.549.317a.5.5 0 11-.5-.866L5 7l-.549-.317a.5.5 0 01.5-.866l.549.317V5.5a.5.5 0 111 0m-2 4.5h5a.5.5 0 010 1h-5a.5.5 0 010-1m0 2h5a.5.5 0 010 1h-5a.5.5 0 010-1"/>',
    },
});
